import React from "react";
import { Grid } from "@mui/material";
import {  Box } from "@mui/joy";
import Typography from "@mui/joy/Typography";

import LSlogo from "./LSlogo.png";

const NavBar: React.FC = () => {
  return (
    <Box
      sx={{
        height: 90,
        // bgcolor: "primary.300",
        zIndex: 100,
        mb: 2,
        paddingTop: 4,
        marginBottom: 6,
        // borderBottom: "solid black 1px"
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          flexGrow: 1,
          alignItems: "center",
        }}
      >
        <Grid xs />

        <Grid
          xs={8}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Box
            component="nav"
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "8px 16px",
              backgroundColor: "primary.main",
            }}
          >
            <Box
              component="img"
              src={LSlogo}
              alt="Company Logo"
              sx={{
                height: 70,
                width: 50,
                marginRight: 2, // Adjust the spacing as needed
              }}
            />
            <Typography
              level="h1"
              sx={{
                color: "primary.contrastText",
              }}
            >
              LocalityStats
            </Typography>
          </Box>
        </Grid>
        <Grid xs />
      </Grid>
    </Box>
  );
};

export default NavBar;
