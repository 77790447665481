import React from 'react';
import { Alert, Badge, Button, Card, Grid, Input, Typography } from '@mui/joy';
import EmailIcon from '@mui/icons-material/Email';
import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from '@mui/icons-material/Warning';

import LightbulbIcon from '@mui/icons-material/Lightbulb';
import config from '../config';

const SuggestionBox: React.FC = () => {



    const [suggestionData, setSuggestionData] = React.useState('');
    const [suggestionSubmitted, setSuggestionSubmitted] = React.useState(false);
    
    const [emailAddrError, setEmailAddrError] = React.useState(false);
  
  
    const handleSubmit = async () => {
  
      if (!suggestionData) {
        console.error('No suggestions == happy customer :-)');
        
        return;
      }
  
    //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //   if (!emailRegex.test(emailData)) {
    //     // console.error('Invalid email address');
    //     setEmailAddrError(true);
    //     return;
    //   }
  
      const response = await fetch(`${config.apiBaseUrl}/suggestionbox`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ suggestion: suggestionData }),
      });
  
      if (response.ok) {
          setSuggestionSubmitted(true);
          setEmailAddrError(false);
          console.log('suggestion submitted successfully');
      } else {
          // console.error('Failed to submit email');
      }
    };
  
    React.useEffect(() => {
      if (suggestionData === '') {
        setSuggestionSubmitted(false);
        setEmailAddrError(false);
      }
    }, [suggestionData]);
  
  










    return (
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>

        <Grid xs />

        <Grid xs={10} md={8}>
            <Card variant="solid" invertedColors
                sx={{
                    bgcolor: "primary.900",
                    marginTop: 6,
                    marginBottom: 4,
                    paddingBottom: 5,
                    width: '100%'
                }}
            >
                <Grid container spacing={2} sx={{ flexGrow: 1, marginTop: 3, marginBottom: 2 }} direction="row"
                    justifyContent="center"
                    alignItems="center">

                    If you notice any data anomolies or errors, or have any other feedback, we would love to hear it. ✌🏼
                </Grid> 
                <Grid container spacing={2} sx={{ flexGrow: 1, marginTop: 3, marginBottom: 2 }} direction="row"
                    justifyContent="center"
                    alignItems="center">



                        {emailAddrError ? 
                            <Alert
                            startDecorator={<WarningIcon />}
                            variant="solid"
                            color="danger"
                            sx={{ m: 1 }}
                            >
                            Please check email address
                            </Alert> : ""}

                        <Badge
                            variant="solid"
                            badgeContent={<CheckIcon />}
                            invisible={!suggestionSubmitted}
                            size="md"
                            badgeInset="2%"
                            >
                            
                        <Input
                        startDecorator={<LightbulbIcon />}
                        placeholder={  "Suggestions welcome"}
                        onChange={(e) => setSuggestionData(e.target.value)}
                        value={suggestionSubmitted ? "thanks!" : suggestionData}
                        endDecorator={<Button onClick={handleSubmit}>Submit</Button>}
                        sx={{ width: '350px' }}
                        ></Input>
                        </Badge>




                </Grid>
            </Card>
        </Grid>

        <Grid xs />

      </Grid>
    );
}

export default SuggestionBox;