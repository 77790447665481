// // context/DataContext.tsx
// import React, { createContext, useContext, useState, ReactNode } from 'react';


// interface DataState {
//   data: string;
//   setData: (data: string) => void;
// }

// const DataContext = createContext<DataState | undefined>(undefined);


// interface DataProviderProps {
//   children: ReactNode;  // Using ReactNode to type children
// }

// export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
//   const [data, setData] = useState<string>('');

//   return (
//     <DataContext.Provider value={{ data, setData }}>
//       {children}
//     </DataContext.Provider>
//   );
// };

// export const useData = () => {
//   const context = useContext(DataContext);
//   if (!context) throw new Error('useData must be used within a DataProvider');
//   return context;
// };


// context/DataContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { SearchDataItem } from '../types/types';

// type SearchDataItem = {
//   contract_date: string;
//   locality: string;
//   postcode: string;
//   sale_price: number;
//   street_address: string;
// };

interface DataState {
  data: SearchDataItem[];
  setData: (data: SearchDataItem[]) => void;
}

const DataContext = createContext<DataState | undefined>(undefined);

interface DataProviderProps {
  children: ReactNode;
}

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
  const [data, setData] = useState<SearchDataItem[]>([]);

  return (
    <DataContext.Provider value={{ data, setData }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  const context = useContext(DataContext);
  if (!context) throw new Error('useData must be used within a DataProvider');
  return context;
};
