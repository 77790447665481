import * as React from "react";
import { useData } from "../context/DataContext";

import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";

import SearchIcon from "@mui/icons-material/Search";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  Box,
  Chip,
  ChipDelete,
  FormHelperText,
  Input,
  Tooltip,
  Badge,
  Grid,
  Alert,
} from "@mui/joy";

import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import EmailIcon from '@mui/icons-material/Email';
import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from '@mui/icons-material/Warning';


import Select, { SelectStaticProps } from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import IconButton from "@mui/joy/IconButton";
import CloseRounded from "@mui/icons-material/CloseRounded";
import { Divider } from "@mui/material";
import { calculateDateRange } from "../utils/utils";
import config from '../config';

/******************************************************************************/
/*                            COMPONENT                                       */

const SearchCard2: React.FC = () => {
  /**************************************************************************/
  /*                        state variables                                 */

  const [searchInput, setSearchInput] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");

  const [manualDates, setManualDates] = React.useState(true);

  const [searchUnderway, setSearchUnderway] = React.useState(false);
  const [badSearchQuery, setBadSearchQuery] = React.useState(false);

  const [invalidLocations, setInvalidLocations] = React.useState('');

  const { setData } = useData();

  // two start vars used with period selection
  const [value, setValue] = React.useState<string | null>("");
  const action: SelectStaticProps["action"] = React.useRef(null);

  /**************************************************************************/
  /*                        handler functions                               */

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value);
  };

  const handleSearchClick = async (event: React.FormEvent) => {
    event.preventDefault();
    const query = queryBuilder();
    if (query !== null) {
      setBadSearchQuery(false);
      setSearchUnderway(true);
      try {
        const response = await fetch(`${config.apiBaseUrl}/search?${query.toString()}`);
        const result = await response.json();
        setSearchUnderway(false);
        
        if (result.invalid_search) {
          alert(`Invalid search: ${result.invalid_search}`);
          setInvalidLocations(result.invalid_search);
        } else {
          setInvalidLocations('');
          setData(result.searchData);
        }
      } catch (error) {
        console.error('Error fetching search results:', error);
        setSearchUnderway(false);
        alert('An error occurred while fetching search results. Please try again.');
      }
    } else {
      setBadSearchQuery(true);
    }
  };

  function queryBuilder(): object | null {
    // Check if any of the inputs are empty
    // TODO: add more advanced pre-validation here.
    if (!searchInput.trim() || !startDate.trim() || !endDate.trim()) {
      return null; // Return null if any field is empty
    }

    return new URLSearchParams({
      locations: searchInput,
      startDate: startDate,
      endDate: endDate,
    });
  }

  const checkDate = (date: string): boolean => {
    // Regular expression to match the MM-YYYY format
    const regex = /^(0[1-9]|1[0-2])-(\d{4})$/;

    const match = date.match(regex);
    if (match) {
      // Extract month and year from the regex match
      const month = parseInt(match[1], 10);
      const year = parseInt(match[2], 10);

      // Check month between 1 and 12 and year is a reasonable value
      // TODO: get from current year?
      return month >= 1 && month <= 12 && year >= 1990 && year <= 2025;
    }
    return false;
  };

  const getLocationChips = () => {
    const locChips = searchInput
      .split(",")
      .map((value) => value.trim())
      .filter((value) => value !== "");
    return locChips.map((value, index) => <Chip key={index}>{value}</Chip>);
  };

  React.useEffect(() => {
    // console.log("value has changed:", value);
    if (value === null) {
      // when value changes if it changes to null, enable normal period search
      // unset start date (or to default)
      // unset end date (or to default)
      setStartDate("");
      setEndDate("");
      // renable inputs for start and end date.
      setManualDates(true);
    } else {
      // if it changes to a number (str) value, then automatically set dates.
      const [autoStartDate, autoEndDate] = calculateDateRange(value);
      setStartDate(autoStartDate);
      setEndDate(autoEndDate);
      // set start and end date to disabled
      setManualDates(false);
    }
  }, [value]);


  const [emailData, setEmailData] = React.useState('');
  const [emailSubmitted, setEmailSubmitted] = React.useState(false);
  
  const [emailAddrError, setEmailAddrError] = React.useState(false);

  const handleSubmit = async () => {

    if (!emailData) {
      console.error('Email is required');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailData)) {
      setEmailAddrError(true);
      return;
    }

    const response = await fetch(`${config.apiBaseUrl}/emailsubmit`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: emailData }),
    });

    if (response.ok) {
        setEmailSubmitted(true);
        setEmailAddrError(false);
        console.log('Email submitted successfully');
    } else {
        // console.error('Failed to submit email');
    }
  };

  React.useEffect(() => {
    if (emailData === '') {
      setEmailSubmitted(false);
      setEmailAddrError(false);
    }
  }, [emailData]);







  /**************************************************************************/
  /*                        componentry                                     */

  return (

    
<Card variant="solid" invertedColors
      sx={{
        bgcolor: "primary.900",
        marginTop: 2,
        marginBottom: 1,
        paddingBottom: 5,
      }}
    >



<Tabs aria-label="Basic tabs" defaultValue={0}>
  <TabList>
    <Tab>Search</Tab>
    <Tab>About</Tab>
    <Tab>Newsletter</Tab>
  </TabList>
  <TabPanel value={0}>
   
      {/********************************************************************/}
      <Grid container spacing={2}>
        <Grid xs />
        <Grid xs={12} md={10} direction="row" sx={{ marginTop: 3}}>
          <Grid container spacing={2} columns={12} sx={{ flexGrow: 1 }}>
            {/************** column one ******************************************/}
            <Grid xs={12} md={6} sx={{ paddingRight: 3}} >

              <Typography startDecorator={<SearchIcon />} level="title-lg">
                Enter Search Locations
              </Typography>

              <Box sx={{ marginBottom: 2 }}>
                <Tooltip
                  placement="top-start"
                  variant="outlined"
                  arrow
                  title={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: 320,
                        justifyContent: "center",
                        p: 1,
                      }}
                    >
                      <Box
                        sx={{ display: "flex", gap: 1, width: "100%", mt: 1 }}
                      >
                        <div>
                          <Typography fontWeight="lg" fontSize="sm">
                            How to:
                          </Typography>
                          <Typography
                            textColor="text.secondary"
                            fontSize="sm"
                            sx={{ mb: 1 }}
                          >
                            Enter search locations separated by a comma. Eg:
                            Winterfell, King's Landing, Bravos
                          </Typography>
                        </div>
                      </Box>
                    </Box>
                  }
                >
                  <Input
                    placeholder="Enter Search Locations"
                    onChange={handleInputChange}
                    sx={{ marginTop: 2, marginBottom: 2 }}
                  />
                </Tooltip>
                <FormHelperText>{getLocationChips()}</FormHelperText>
              </Box>

          

                {badSearchQuery ? (
                  <Box sx={{ marginTop: 2 }}>
                    <Chip
                      variant="solid"
                      color="danger"
                      endDecorator={
                        <ChipDelete onDelete={() => setBadSearchQuery(false)} />
                      }
                    >
                      invalid search parameters
                    </Chip>
                  </Box>
                ) : (
                  ""
                )}

                {invalidLocations ? <>Invalid search: {invalidLocations}</> :''}

            </Grid>

            {/**************column two *******************************************/}
            <Grid xs={12} md={6} >
              <Typography
                startDecorator={<CalendarMonthIcon />}
                level="title-lg"
              >
                Enter a period
              </Typography>
              <Grid xs={12}>
                <Badge
                  variant="solid"
                  badgeContent={<CheckIcon />}
                  invisible={!checkDate(startDate)}
                  size="md"
                  badgeInset="2%"
                >
                  <Input
                    placeholder={
                      startDate === "" ? "start date MM-YYYY" : startDate
                    }
                    onChange={handleStartDateChange}
                    sx={{ marginTop: 2 }}
                    disabled={!manualDates}
                  />
                </Badge>
              </Grid>
              <Grid xs={12}>
                <Badge
                  variant="solid"
                  badgeContent={<CheckIcon />}
                  invisible={!checkDate(endDate)}
                  size="md"
                  badgeInset="2%"
                >
                  <Input
                    placeholder={endDate === "" ? "end date MM-YYYY" : endDate}
                    onChange={handleEndDateChange}
                    sx={{ marginTop: 3 }}
                    disabled={!manualDates}
                  />
                </Badge>
              </Grid>

              <Divider sx={{ marginTop: 3 }}>or</Divider>

              <Select
                action={action}
                value={value}
                placeholder="Select period"
                onChange={(e, newValue) => setValue(newValue)}
                {...(value && {
                  // display the button and remove select indicatr
                  // when user has selected a value
                  endDecorator: (
                    <IconButton
                      size="sm"
                      variant="plain"
                      color="neutral"
                      onMouseDown={(event) => {
                        // don't open the popup when clicking
                        event.stopPropagation();
                      }}
                      onClick={() => {
                        setValue(null);
                        action.current?.focusVisible();
                      }}
                    >
                      <CloseRounded />
                    </IconButton>
                  ),
                  indicator: null,
                })}
                sx={{ width: 180, marginTop: 3 }}
              >
                <Option value="1">Last 1 month</Option>
                <Option value="3">Last 3 months</Option>
                <Option value="6">Last 6 months</Option>
                <Option value="12">Last year</Option>
              </Select>
            </Grid>

            {/********************************************************************/}

            <Box sx={{ marginTop: 8 }}>
                <Button variant="soft" size="lg" onClick={handleSearchClick}>
                  Search
                </Button>
                <Box sx={{ marginTop: 3, marginBottom: 3 }}>
                  {searchUnderway ? <CircularProgress /> : ""}
                </Box>

              </Box>

          </Grid>
        </Grid>
        <Grid xs />
      </Grid>
      {/********************************************************************/}

        </TabPanel>
        <TabPanel value={1}>

          <Typography>
            We went through the home buying process recently.
          </Typography>
          <br />
          <Typography>
            It was an opaque experience, despite sales data existing online freely available (although not in the most intuitive format).
          </Typography>
          <br />
          <Typography>
            This project is an attempt to make things more transparent. 
          </Typography>
          <br />
          <Typography>
            Whether you're actively seeking a new home or investment property, or are just casually curious, you might find some useful insights in the data here.
          </Typography>
          <br />
          <Typography>
            LocalityStats.com.au is currently running sales data from NSW only. The plan is to add more states in the near future, please feel free to submit any suggestions (below) of what/where you'd like to see (and to give us a kick up the bottom to move faster!)
          </Typography>
          <br />
        </TabPanel>

        <TabPanel value={2}>
          <Typography>
            LocalityStats.com.au will shortly be launching a weekly newsletter which will contain further detailed analysis of hot localities and standout trends. 
          </Typography>
          <br />
          <Typography sx={{marginBottom: 5}}>
            Please submit your email if you're interested in receiving a weekly newsletter. 
          </Typography>

            <br/>

          {emailAddrError ? 
            <Alert
              startDecorator={<WarningIcon />}
              variant="solid"
              color="danger"
              sx={{ m: 1 }}
             >
              Please check email address
            </Alert> : ""}

            <Badge
                  variant="solid"
                  badgeContent={<CheckIcon />}
                  invisible={!emailSubmitted}
                  size="md"
                  badgeInset="2%"
                >
                
            <Input
              startDecorator={<EmailIcon />}
              placeholder={  "Receive Updates"}
              onChange={(e) => setEmailData(e.target.value)}
              value={emailSubmitted ? "thanks!" : emailData}
              endDecorator={<Button onClick={handleSubmit}>Keep me updated!</Button>}
              sx={{ marginBottom: 3}}
              ></Input>
              </Badge>

        </TabPanel>
      </Tabs>

    </Card>

  );
};

export default SearchCard2;
