import React from "react";
import { useData } from "../context/DataContext";
import { Grid, Typography } from "@mui/joy";
import CustomScatterPlot from "./CustomScatterPlot";

const ChartPanel2: React.FC = () => {
  const { data } = useData();

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid container md />

      <Grid container xs={12} md={8}>
        <Typography level="h2" sx={{ marginTop: 3, marginBottom: 3 }}>
          Property Sales
        </Typography>

        <Grid sx={{ marginBottom: 5 }}>
          <CustomScatterPlot data={data} />
        </Grid>
      </Grid>

      <Grid container md />
    </Grid>
  );
};

export default ChartPanel2;
