import React from 'react';
import { Grid, Typography } from '@mui/joy';

interface SearchDataItem {
    locality: string;
    // other properties...
}

interface SearchResultsHeaderProps {
    searchData: SearchDataItem[];
}

const SearchResultsHeader: React.FC<SearchResultsHeaderProps> = ({ searchData }) => {
    const uniqueLocalities = Array.from(new Set(searchData.map((item) => item.locality.toLowerCase())));

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{marginTop: '100px'}}
        >
            <Typography level="h1">
                {uniqueLocalities.map((locality) => locality.replace(/\b\w/g, (c) => c.toUpperCase())).join(', ')}
            </Typography>
        </Grid>
    );
};

export default SearchResultsHeader;
