import React from "react";
import { AppBar, Box, Grid, ThemeProvider, Toolbar } from "@mui/material";
import { Alert, Badge, Button, Container, Input, Link, Typography } from "@mui/joy";
import EmailIcon from '@mui/icons-material/Email';
import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/joy/IconButton';
import SuggestionBox from "./SuggestionBox";
import CustomDivider from "./CustomDivider";
import config from '../config';


const Footer: React.FC = () => {

  const [emailData, setEmailData] = React.useState('');
  const [emailSubmitted, setEmailSubmitted] = React.useState(false);
  
  const [emailAddrError, setEmailAddrError] = React.useState(false);


  const handleSubmit = async () => {

    if (!emailData) {
      console.error('Email is required');
      
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailData)) {
      // console.error('Invalid email address');
      setEmailAddrError(true);
      return;
    }

    const response = await fetch(`${config.apiBaseUrl}/emailsubmit`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: emailData }),
    });

    if (response.ok) {
        setEmailSubmitted(true);
        setEmailAddrError(false);
        console.log('Email submitted successfully');
    } else {
        // console.error('Failed to submit email');
    }
  };

  React.useEffect(() => {
    if (emailData === '') {
      setEmailSubmitted(false);
      setEmailAddrError(false);
    }
  }, [emailData]);


  return (
    <Box
      sx={{
        bottom: 0, // Add this line
        marginTop: 2,
        height: 650,
        width: "100%",
        bgcolor: "primary.900",
        zIndex: 100,
        paddingTop: 4,
        paddingBottom: 8,
        borderTop: 1,
        //   '&:hover': {
        //     bgcolor: 'primary.400',
        //   },
      }}
    >
      <SuggestionBox />

      <CustomDivider dividerTitle="~"/>

      <Grid
        container
        spacing={2}
        sx={{
          flexGrow: 1,
          alignItems: "center",
        }}
      >
        <Grid xs md />

        <Grid
          xs={12}
          md={8}
          container
          spacing={2}
          sx={{ flexGrow: 1 }}

          // sx={{ direction: "col", justifyContent: "space-between"}}
        >




          <Grid xs={6} md={4} alignItems="flex-start">
            <Typography level="body-sm"><b>hello[at]localitystats.com.au</b></Typography>
            <br/>
            <Typography level="body-sm">
              Data provided by{" "}
              <Link
                href="https://valuation.property.nsw.gov.au/"
                sx={{ color: "#fff", fontStyle: "italic" }}
              >
                Crown in right of NSW through the Valuer General
              </Link>
              licensed under{" "}
              <Link
                href="https://creativecommons.org/licenses/by/4.0/"
                sx={{ color: "#fff" }}
              >
                CC BY 4.0
              </Link>
              {" "}© Crown in right of NSW through the Valuer General 2024.
              Modified from original (spelling corrections mostly).
            </Typography>

            {/* <Typography level='body-sm' >Heading</Typography>
              <Typography level='body-sm' >some more different text</Typography>
              <Typography level='body-sm' >a link or two</Typography>
              <Typography level='body-sm' >to optimal policy??</Typography> */}
          </Grid>

          <Grid xs md />
          <Grid xs={6} md={4} justifyContent="flex-end">
            <Typography level="body-sm">
              <Link href="/disclaimer" sx={{ color: "#fff", marginBottom: 2 }}>
                Disclaimer
              </Link>
            </Typography>

              {emailAddrError ? 
            <Alert
              startDecorator={<WarningIcon />}
              variant="solid"
              color="danger"
              sx={{ m: 1 }}
             >
              Please check email address
            </Alert> : ""}

            <Badge
                  variant="solid"
                  badgeContent={<CheckIcon />}
                  invisible={!emailSubmitted}
                  size="md"
                  badgeInset="2%"
                >
                
            <Input
              startDecorator={<EmailIcon />}
              placeholder={  "Say Hi!"}
              onChange={(e) => setEmailData(e.target.value)}
              value={emailSubmitted ? "thanks!" : emailData}
              endDecorator={<Button onClick={handleSubmit}>Hello!</Button>}
              sx={{ marginBottom: 3}}
              ></Input>
              </Badge>


            <Typography level="body-sm">
              © {new Date().getFullYear()} {" "}
              <Link
                href="https://optimalpolicy.ai"
                sx={{ color: "#fff", fontWeight: '500' }}
              >
                OptimalPolicy
              </Link>. All rights reserved.
            </Typography>
          </Grid>
        </Grid>

        <Grid xs md/>

      </Grid>

    </Box>
  );
};

export default Footer;
