import { MetricProps } from '../components/SpecificMetric';
import { SearchDataItem } from '../types/types'; // Adjust the import path as necessary

// Define the AveragePriceRecord type if not already defined elsewhere
// export type AveragePriceRecord = {
//   date: string;
//   location: string;
//   average_price: number;
// };

// export const calculateAveragePrices = (data: SearchDataItem[]): AveragePriceRecord[] => {
//   const groupedData: Record<string, { total: number, count: number }> = {};

//   // Group data by 'Month-Year' and 'location'
//   data.forEach(record => {
//     const date = new Date(record.contract_date);
//     const monthYear = date.toLocaleDateString("en-US", { month: "short", year: "numeric" });
//     const key = `${monthYear}|${record.locality}`;

//     if (groupedData[key]) {
//       groupedData[key].total += record.sale_price;
//       groupedData[key].count += 1;
//     } else {
//       groupedData[key] = { total: record.sale_price, count: 1 };
//     }
//   });

//   // Convert grouped data to desired output format
//   const result: AveragePriceRecord[] = Object.keys(groupedData).map(key => {
//     const [date, location] = key.split('|');
//     const { total, count } = groupedData[key];
//     return {
//       date,
//       location,
//       average_price: Math.round(total / count),
//     };
//   });
 
//   return result;
// };



// export type AveragePriceRecord = {
//   date: string;
//   location: string;
//   average_price: number;
//   timestamp: number; // Include timestamp for sorting
// };

// export const calculateAveragePrices = (data: SearchDataItem[]): AveragePriceRecord[] => {
//   const groupedData: Record<string, { total: number, count: number, earliestTimestamp: number }> = {};

//   // Group data by 'Month-Year' and 'location'
//   data.forEach(record => {
//     const date = new Date(record.contract_date);
//     const monthYear = date.toLocaleDateString("en-US", { month: "short", year: "numeric" });
//     const key = `${monthYear}|${record.locality}`;

//     if (groupedData[key]) {
//       groupedData[key].total += record.sale_price;
//       groupedData[key].count += 1;
//       // Update the earliest timestamp for this group if current record is earlier
//       if (record.uets < groupedData[key].earliestTimestamp) {
//         groupedData[key].earliestTimestamp = record.uets;
//       }
//     } else {
//       groupedData[key] = { total: record.sale_price, count: 1, earliestTimestamp: record.uets };
//     }
//   });

//   // Convert grouped data to desired output format and sort by timestamp
//   const result: AveragePriceRecord[] = Object.keys(groupedData).map(key => {
//     const [date, location] = key.split('|');
//     const { total, count, earliestTimestamp } = groupedData[key];
//     return {
//       date,
//       location,
//       average_price: Math.round(total / count),
//       timestamp: earliestTimestamp // Include timestamp in the output for sorting
//     };
//   });

//   // Sort the results by timestamp
//   result.sort((a, b) => a.timestamp - b.timestamp);

//   return result;
// };








// export type AggregatedRecord = {
//   date: string;
//   timestamp: number;
//   [location: string]: number;
// };

// export const calculateAveragePrices = (data: SearchDataItem[]): AggregatedRecord[] => {
//   const groupedData: Record<string, { [location: string]: { total: number, count: number } }> = {};

//   // Group data by 'Month-Year' and aggregate by location
//   data.forEach(record => {
//     const date = new Date(record.contract_date);
//     const monthYear = date.toLocaleDateString("en-US", { month: "short", year: "numeric" });
//     const locationKey = record.locality;
//     const key = monthYear;

//     if (!groupedData[key]) {
//       groupedData[key] = {};
//     }

//     if (!groupedData[key][locationKey]) {
//       groupedData[key][locationKey] = { total: record.sale_price, count: 1 };
//     } else {
//       groupedData[key][locationKey].total += record.sale_price;
//       groupedData[key][locationKey].count += 1;
//     }
//   });

//   // Convert grouped data to desired output format with dynamic location keys
//   const result: AggregatedRecord[] = Object.keys(groupedData).map(date => {
//     const timestamp = new Date(date).getTime();  // Convert date string to timestamp
//     const entries = Object.keys(groupedData[date]);
//     const record: AggregatedRecord = { date, timestamp };

//     entries.forEach(location => {
//       const { total, count } = groupedData[date][location];
//       record[location] = Math.round(total / count);
//     });

//     return record;
//   });

//   return result;
// };


// export type SearchDataItem = {
//     contract_date: string;
//     uets: number;
//     locality: string;
//     postcode: string;
//     sale_price: number;
//     street_address: string;
// };

export type averagePrice = {
    location: string,
    average_price: number
}

export type AggregatedRecord = {
    date: string,
    timestamp: number,
    averagePrices: averagePrice[]
}

/**
 * This function calculates the average prices for each location per month,
 * and organizes them into an array of AggregatedRecord.
 */
export const calculateAveragePrices = (data: SearchDataItem[]): AggregatedRecord[] => {
    const groupedData: Record<string, { [location: string]: { total: number, count: number } }> = {};

    // Group data by 'Month-Year' and aggregate by location
    data.forEach(record => {
        if (record.outlier === 1) {
            return; // Skip this record and continue the loop
        }

        const date = new Date(record.contract_date);
        const monthYear = date.toLocaleDateString("en-US", { month: "short", year: "numeric" });
        const key = monthYear;

        if (!groupedData[key]) {
            groupedData[key] = {};
        }

        if (!groupedData[key][record.locality]) {
            groupedData[key][record.locality] = { total: record.sale_price, count: 1 };
        } else {
            groupedData[key][record.locality].total += record.sale_price;
            groupedData[key][record.locality].count += 1;
        }
    });

    // Convert grouped data to desired output format
    const result: AggregatedRecord[] = Object.keys(groupedData).map(date => {
        // Calculate timestamp for the middle of each month
        const [month, year] = date.split(' ');
        const monthIndex = new Date(`${month} 1, ${year}`).getMonth();
        const middleDay = new Date(Number(year), monthIndex, 15).getTime(); // Use the 15th of the month for consistency

        const averagePrices: averagePrice[] = Object.keys(groupedData[date]).map(location => {
            const { total, count } = groupedData[date][location];
            return {
                location,
                average_price: Math.round(total / count)
            };
        });

        return {
            date,
            timestamp: middleDay,
            averagePrices
        };
    });

    // Sort results by timestamp
    result.sort((a, b) => a.timestamp - b.timestamp);

    return result;
};







/*       
    ***** note: 
    
    TODO:
        need to change variable names and
        refactor to use common type as average price
        NB function name, only difference is returns count
        not average price. 

        `don't repeat yourself` -> refactor needed.
*/
export const calculateSaleCounts = (data: SearchDataItem[]): AggregatedRecord[] => {
    const groupedData: Record<string, { [location: string]: { total: number, count: number } }> = {};

    // Group data by 'Month-Year' and aggregate by location
    data.forEach(record => {
        if (record.outlier === 1) {
            return; // Skip this record and continue the loop
        }

        const date = new Date(record.contract_date);
        const monthYear = date.toLocaleDateString("en-US", { month: "short", year: "numeric" });
        const key = monthYear;

        if (!groupedData[key]) {
            groupedData[key] = {};
        }

        if (!groupedData[key][record.locality]) {
            groupedData[key][record.locality] = { total: record.sale_price, count: 1 };
        } else {
            groupedData[key][record.locality].total += record.sale_price;
            groupedData[key][record.locality].count += 1;
        }
    });

     // Convert grouped data to desired output format
     const result: AggregatedRecord[] = Object.keys(groupedData).map(date => {
        // Calculate timestamp for the middle of each month
        const [month, year] = date.split(' ');
        const monthIndex = new Date(`${month} 1, ${year}`).getMonth();
        const middleDay = new Date(Number(year), monthIndex, 15).getTime(); // Use the 15th of the month for consistency

        const averagePrices: averagePrice[] = Object.keys(groupedData[date]).map(location => {
            const { total, count } = groupedData[date][location];
            return {
                location,
                average_price: count
            };
        });

        return {
            date,
            timestamp: middleDay,
            averagePrices
        };
    });

    // Sort results by timestamp
    result.sort((a, b) => a.timestamp - b.timestamp);

    return result;
};
























































export const calculateTrimmedMeanPrices = (data: SearchDataItem[]): AggregatedRecord[] => {
    const groupedData: Record<string, { [location: string]: number[] }> = {};

    // Group data by 'Month-Year' and location, collecting prices into arrays
    data.forEach(record => {
        const date = new Date(record.contract_date);
        const monthYear = date.toLocaleDateString("en-US", { month: "short", year: "numeric" });
        const key = monthYear;

        if (!groupedData[key]) {
            groupedData[key] = {};
        }
        if (!groupedData[key][record.locality]) {
            groupedData[key][record.locality] = [];
        }
        groupedData[key][record.locality].push(record.sale_price);
    });

    // Convert grouped data to desired output format with trimmed mean
    const result: AggregatedRecord[] = Object.keys(groupedData).map(date => {
        const timestamp = new Date(date).getTime();
        const averagePrices: averagePrice[] = Object.entries(groupedData[date]).map(([location, prices]) => {
            prices.sort((a, b) => a - b);
            const trimmed = prices.slice(1, -1); // Remove the lowest and highest
            const sum = trimmed.reduce((acc, cur) => acc + cur, 0);
            const average_price = trimmed.length ? Math.round(sum / trimmed.length) : 0;
            return {
                location,
                average_price
            };
        });

        return {
            date,
            timestamp,
            averagePrices
        };
    });

    // Sort results by timestamp
    result.sort((a, b) => a.timestamp - b.timestamp);

    return result;
};



// /**
//  * Calculates the median prices for each location per month,
//  * organizing them into an array of AggregatedRecord.
//  */
// export const calculateMedianPrices = (data: SearchDataItem[]): AggregatedRecord[] => {
//     const groupedData: Record<string, { [location: string]: number[] }> = {};

//     // Group data by 'Month-Year' and location, collecting prices into arrays
//     data.forEach(record => {
//         const date = new Date(record.contract_date);
//         const monthYear = date.toLocaleDateString("en-US", { month: "short", year: "numeric" });
//         const key = monthYear;

//         if (!groupedData[key]) {
//             groupedData[key] = {};
//         }
//         if (!groupedData[key][record.locality]) {
//             groupedData[key][record.locality] = [];
//         }
//         groupedData[key][record.locality].push(record.sale_price);
//     });

//     // Convert grouped data to desired output format with median calculations
//     const result: AggregatedRecord[] = Object.keys(groupedData).map(date => {
//         const timestamp = new Date(date).getTime(); // Calculate timestamp for the middle of each month
//         const averagePrices: averagePrice[] = Object.entries(groupedData[date]).map(([location, prices]) => {
//             // Sort prices and calculate median
//             prices.sort((a, b) => a - b);
//             const middleIndex = Math.floor(prices.length / 2);
//             const median = prices.length % 2 !== 0 ? prices[middleIndex] : (prices[middleIndex - 1] + prices[middleIndex]) / 2;

//             return {
//                 location,
//                 average_price: Math.round(median)
//             };
//         });

//         return {
//             date,
//             timestamp,
//             averagePrices
//         };
//     });

//     // Sort results by timestamp for consistent chronological order
//     result.sort((a, b) => a.timestamp - b.timestamp);

//     return result;
// };


/**
 * Calculates and interpolates median prices for each location per month,
 * organizing them into an array of AggregatedRecord.
 */
export const calculateMedianPrices = (data: SearchDataItem[]): AggregatedRecord[] => {
    const groupedData: Record<string, { [location: string]: number[] }> = {};

    // Group data by 'Month-Year' and location, collecting prices into arrays
    data.forEach(record => {
        const date = new Date(record.contract_date);
        const monthYear = date.toLocaleDateString("en-US", { month: "short", year: "numeric" });
        const key = monthYear;

        if (!groupedData[key]) {
            groupedData[key] = {};
        }
        if (!groupedData[key][record.locality]) {
            groupedData[key][record.locality] = [];
        }
        groupedData[key][record.locality].push(record.sale_price);
    });

    // Prepare to calculate median with interpolation
    const locationMedians: Record<string, number[]> = {};
    const result: AggregatedRecord[] = [];

    // First pass: calculate medians without interpolation
    Object.keys(groupedData).forEach(date => {
        const timestamp = new Date(date).getTime();
        Object.entries(groupedData[date]).forEach(([location, prices]) => {
            prices.sort((a, b) => a - b);
            const validPrices = prices.filter(price => price > 0);
            const median = validPrices.length ? (validPrices.length % 2 !== 0 ? validPrices[Math.floor(validPrices.length / 2)] :
                (validPrices[Math.floor(validPrices.length / 2) - 1] + validPrices[Math.floor(validPrices.length / 2)]) / 2) : 0;
            
            if (!locationMedians[location]) {
                locationMedians[location] = [];
            }
            locationMedians[location].push(median);
            
            const existingRecord = result.find(r => r.date === date);
            if (!existingRecord) {
                result.push({ date, timestamp, averagePrices: [] });
            } else {
                existingRecord.averagePrices.push({ location, average_price: median });
            }
        });
    });

    // Second pass: interpolate zeros
    result.forEach((record, index, array) => {
        record.averagePrices.forEach(ap => {
            if (ap.average_price === 0) {
                const previous = locationMedians[ap.location].slice(0, index).reverse().find(m => m > 0) || 0;
                const next = locationMedians[ap.location].slice(index + 1).find(m => m > 0) || previous;
                ap.average_price = (previous + next) / 2;
            }
        });
    });

    // Sort results by timestamp for consistent chronological order
    result.sort((a, b) => a.timestamp - b.timestamp);

    return result;
};



export const calculateDateRange = (param: string): [string, string] => {
    const currentDate = new Date();
    const monthsToSubtract = parseInt(param, 10);
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - monthsToSubtract, 1);
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const formattedStartDate = startDate.toLocaleDateString("en-US", { month: "2-digit", year: "numeric" }).replace("/", "-");
    const formattedEndDate = endDate.toLocaleDateString("en-US", { month: "2-digit", year: "numeric" }).replace("/", "-");
    return [formattedStartDate, formattedEndDate];
};



/*

input parameter: { data: SearchDataItem[] }

function:

    for each unique location in the input data:
        calculate the average price delta over the most recent (last) 3 period intervals.

    for the sake of debugging, log the periods used, the respective location, and the calculated value to the console.

    return the data formatted like so:
    
    [ { locationA: { metricName: string, relativeValue: number, metricValue: string }},
      { locationB: { metricName: string, relativeValue: number, metricValue: string }},
        ...
    ]
    */

    export const calculateAveragePriceDelta = (data: SearchDataItem[]): Record<string, { metricName: string, relativeValue: number, metricValue: string }> => {
        const uniqueLocations = Array.from(new Set(data.map(item => item.locality)));
        const result: Record<string, { metricName: string, relativeValue: number, metricValue: string }> = {};

        uniqueLocations.forEach(location => {
            const locationData = data.filter(item => item.locality === location);
            const periods = locationData.slice(-3);
            const averagePriceDelta = calculateAveragePrice(periods) - calculateAveragePrice(locationData);

            // console.log(`Location: ${location}`);
            // console.log(`Periods: ${periods.map(item => item.contract_date).join(', ')}`);
            // console.log(`Average Price Delta: ${averagePriceDelta}`);

            result[location] = {
                metricName: 'Average Price Delta',
                relativeValue: averagePriceDelta,
                metricValue: `${averagePriceDelta.toFixed(2)}%`
            };
        });

        return result;
    };

    

// export type averagePrice = {
//     location: string,
//     average_price: number
// }

// export type AggregatedRecord = {
//     date: string,
//     timestamp: number,
//     averagePrices: averagePrice[]
// }

export type APDs = {
    location: string,
    metric_info: MetricProps
}

export const calculateAveragePriceDeltas = (data: SearchDataItem[]): APDs[] => {
    const uniqueLocations = Array.from(new Set(data.map(item => item.locality)));
    const result: APDs[] = [];

    uniqueLocations.forEach(location => {
        const locationData = data.filter(item => item.locality === location);
        const averagePrice = calculateAveragePrice(locationData);
        const metricValue = (averagePrice).toFixed(2); // Calculate as percentage
        const metricName = "Average Price";
        const relativeValue = calculateRelativeValue(locationData, averagePrice);

        result.push({
            location,
            metric_info: {
                metricName,
                relativeValue,
                metricValue,
            },
        });
    });

    return result;
};

const calculateAveragePrice = (data: SearchDataItem[]): number => {
    const total = data.reduce((sum, item) => sum + item.sale_price, 0);
    return total / data.length;
};

const calculateRelativeValue = (data: SearchDataItem[], averagePrice: number): number => {
    const latestPrice = data[data.length - 1].sale_price;
    return ((latestPrice - averagePrice));
};








// utils/interpolate.ts
export const interpolateZeroValues = (data: AggregatedRecord[]): AggregatedRecord[] => {
    const interpolatedData = data.map(record => {
      const newAveragePrices = record.averagePrices.map((ap, index, arr) => {
        if (ap.average_price === 0) {
          const prev = arr[index - 1];
          const next = arr[index + 1];
          if (prev && next) {
            ap.average_price = (prev.average_price + next.average_price) / 2;
          } else if (prev) {
            ap.average_price = prev.average_price;
          } else if (next) {
            ap.average_price = next.average_price;
          }
        }
        return ap;
      });
      return { ...record, averagePrices: newAveragePrices };
    });
    return interpolatedData;
  };
  