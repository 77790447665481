import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import SearchPanel from "../components/SearchPanel";
import TablePanel from "../components/TablePanel";
import CustomDivider from "../components/CustomDivider";
import { useData } from "../context/DataContext";
import WordAnimator from "../components/WordAnimator";

import top100 from "../static/top100.json"; // Import the top100.json file
import { Box} from "@mui/joy";
import SearchResultsHeader from "../components/SearchResultsHeader";
import ChartPanel2 from "../components/ChartPanel2";
import MetricPanel from "../panels/MetricPanel";
import AddCardPanel from "../panels/AddCardPanel";

const HomePage: React.FC = () => {
  const { data } = useData();

  const words = top100; // Assign the top100 array to the words variable

  return (
    <Box  sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}>
      <NavBar />
      <SearchPanel />
      {/* <AddCardPanel /> */}
      {data.length > 0 ? (
        <>
          <SearchResultsHeader searchData={data} />
          <CustomDivider dividerTitle="Analysis" />
          <ChartPanel2 />
          <br />
          <Box height={3} margin={3} />
          <MetricPanel />
          <CustomDivider dividerTitle="all search results" />
          <TablePanel />
        </>
      ) : (
        <>
          <WordAnimator words={words} interval={750} />{" "}
          <Box sx={{ height: 100, width: "100%" }} />{" "}
        </>
      )}

      <Footer />
      </Box>
  );
};

export default HomePage;
