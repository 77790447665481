import { extendTheme } from '@mui/joy/styles';

const customTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          // 50: '#0640ff',

          // 100: '#e400c8',

          // 200: '#ff0087',

          // 300: '#ff7855', 
          // 300: '#0640ff', 


          // 400: '#ffbf44',

          // button outlines
          500: '#012a4a',
          // 600: '#00b47f',
          // 700: '#00aac0',

          // 800: '#013a63',
          
          // dark blue
          900: '#012a4a',

        },
        // You can customize other palettes (secondary, error, warning, info, success) similarly
        text: {
          // primary: '#ffffff', // Default text color
          primary: '#012a4a', // Default text color
          // secondary: '#fff',
          tertiary: '#fff',
        },
      },
    },
  },
});

export default customTheme;


/*
012a4a
013a63
01497c
014f86
2a6f97
2c7da0
468faf
61a5c2
89c2d9
a9d6e5


8ecae6
219ebc
023047
ffb703
fb8500
*/