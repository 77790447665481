import { Grid } from "@mui/material";
import React from "react";
import SearchCard2 from "./SearchCard2";

const SearchPanel: React.FC = () => {
  return (
    <>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs />
        <Grid xs={10} md={8}>
          <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            <Grid xs={12}>
                  <SearchCard2 />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs />
      </Grid>
    </>
  );
};

export default SearchPanel;
