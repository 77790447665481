// src/components/TextPage.tsx
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Container, Typography, Button } from "@mui/joy";

const DisclaimerPage: React.FC = () => {
  return (
    <Container>
      <Box>
        <Typography level="h2">Disclaimer</Typography>
        <Typography component="pre">
          {`
            While the material contained in this website has been formulated 
            with all due care, the Publisher of this website does not warrant
            or represent that the material is free from errors or omission, 
            or that it is exhaustive.  
            
            The Publisher of this website disclaims, to the extent permitted
            by law, all warranties, representations or endorsements, express
            or implied, with regard to the material including but not limited
            to, all implied warranties of merchantability, fitness for a 
            particular purpose, or non-infringement.  
            
            The Publisher of this website further does not warrant or accept
            any liability in relation to the quality, operability or accuracy
            of the material. The material is made available on the 
            understanding that the Publisher of this website shall have no 
            liability (including but not limited to liability by reason of 
            negligence) to the users of the website for any loss, damage, 
            cost or expense whether direct, indirect consequential or
            special, incurred by, or arising by reason of, any person using 
            or relying on the material and whether caused by reason of any 
            error, omission or misrepresentation in the material or 
            otherwise.  
            
            Users of the website will be responsible for making their own 
            assessment of the material and should verify all relevant 
            representations, statements and information with their own 
            professional advisers. Furthermore, whilst the material is 
            considered to be true and correct at the date of publication, 
            changes in circumstances after the time of publication may 
            impact upon the accuracy of the material. The material may 
            change without notice and the Publisher of this website is not 
            in any way liable for the accuracy of any information printed 
            and stored by a user.  
            
            Changes are periodically added to the material and the Publisher
            of this website may make improvements and/or changes in the 
            material and/or the website at anytime.  
            
            The Publisher of this website takes no responsibility for the 
            accuracy, currency, reliability and correctness of any 
            information included in the material provided by third parties
            nor for the accuracy, currency, reliability and correctness of 
            links or references to information sources (including internet 
            sites) outside of the Publisher of this website .  
            
            In addition to the material, the website provides links to and 
            from other internet sites. These external information sources 
            are outside the control of the Publisher of this website and it 
            is therefore the responsibility of the internet users to make 
            their own decisions about the accuracy, reliability and 
            correctness of information found on those external internet 
            links.  
            
            The search engines provided on this website are to assist 
            internet users to locate resources more quickly. However, the
            Publisher of this website takes no responsibility for the 
            accuracy, currency, reliability and correctness of its search 
            facilities, and does not warrant or represent that the search 
            facilities are free from errors or omission, or that they are 
            exhaustive.  
            
            The internet is not a secure medium and communications to and 
            from the website may be intercepted or altered in transit.  
            
            The Publisher of this website does not warrant or represent that 
            this website or any linked sites, are free from anything which 
            may damage any computer used to access the site. 
            `}
        </Typography>
        <Button component={Link} to="/" variant="outlined" sx={{ mt: 2 }}>
          Return to Home Page
        </Button>
      </Box>
    </Container>
  );
};

export default DisclaimerPage;
