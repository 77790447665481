import React from 'react';
import { SearchDataItem } from '../types/types';
import { Table, Box, IconButton, Button, Chip, ButtonGroup } from '@mui/joy';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HikingIcon from '@mui/icons-material/Hiking';


// Function to create a search URL
const createSearchUrl = (query: string) => {
  return `https://www.google.com/search?q=${encodeURIComponent(query)}`;
}

interface SearchResultsTableProps {
  data: SearchDataItem[];
}


const sortDataByPriceAscending = (data: SearchDataItem[]): SearchDataItem[] => {
  return data.slice().sort((a, b) => a.sale_price - b.sale_price);
};

const sortDataByPriceDescending = (data: SearchDataItem[]): SearchDataItem[] => {
  return data.slice().sort((a, b) => b.sale_price - a.sale_price);
};

const sortDataByUETSAscending = (data: SearchDataItem[]): SearchDataItem[] => {
  return data.slice().sort((a, b) => a.uets - b.uets);
};

const sortDataByUETSDescending = (data: SearchDataItem[]): SearchDataItem[] => {
  return data.slice().sort((a, b) => b.uets - a.uets);
};


// Update the DataTable component to accept props and display the data
const DataTable: React.FC<SearchResultsTableProps> = ({ data }) => {

  const [dynamicData, setDynamicData] = React.useState(data);

  // TODO: use constants for these variables.. yuck.
  const [priceSortOrder, setPriceSortOrder] = React.useState<'a' | 'd' >('a');
  const [dateSortOrder, setDateSortOrder] = React.useState<'a' | 'd'>('a');

  const [sortByPrice, setSortByPrice] = React.useState(false);
  const [sortByDate, setSortByDate]   = React.useState(false);


  const handleSortPrice = () => {
    setSortByPrice(true);
    setSortByDate(false);
    // setSortBy('price');
    setPriceSortOrder(prevMode => (prevMode === 'a' ? 'd' : 'a'));
    if (priceSortOrder === 'a') {
      setDynamicData(sortDataByPriceAscending(dynamicData));
    } else {
      setDynamicData(sortDataByPriceDescending(dynamicData));
    }
  }

  const handleSortDate = () => {
    setSortByPrice(false);
    setSortByDate(true);
    setDateSortOrder( prevMode => (prevMode === 'a' ? 'd' : 'a'));
    if (dateSortOrder === 'a') {
      setDynamicData(sortDataByUETSAscending(dynamicData));
    } else {
      setDynamicData(sortDataByUETSDescending(dynamicData));
    }
  }


  React.useEffect(() => {
    // console.log('data has changed...');
    setDynamicData(data);
  }, [data]);



  return (
    <Box>

      <Box>

        <ButtonGroup aria-label="outlined primary button group" sx={{ marginBottom: 2 }}>
      <Button onClick={handleSortPrice}>Sort by price</Button>
      <Button onClick={handleSortDate}>Sort by date</Button>
      <Chip sx={{ marginLeft: 2 }}>Number of results: {data.length}</Chip>
    </ButtonGroup>
    <Table sx={{ '& thead th:nth-child(1)': { width: '25%' }, '& thead th:nth-child(2)': { width: '25%' } }}>
    {/* <Table > */}
      <thead>
        <tr>
          <th>Street Address</th>
          <th>Locality</th>
          <th>Postcode</th>
          <th>Contract Date
              {sortByDate && dateSortOrder === 'a' && <ArrowUpwardIcon />}
              {sortByDate && dateSortOrder === 'd' && <ArrowDownwardIcon />}
          </th>
          <th>Sale Price 
            {sortByPrice && priceSortOrder === 'a' && <ArrowUpwardIcon />}
              {sortByPrice && priceSortOrder === 'd' && <ArrowDownwardIcon />}
          </th>
            <th>Search</th>

        </tr>
      </thead>
      <tbody>
        {dynamicData.map((item, index) => (
          <tr key={index}>

            <td>{item.street_address}</td>
            <td>{item.locality}</td>
            <td>{item.postcode}</td>
            <td>{new Date(item.contract_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</td>
            <td>${item.sale_price.toLocaleString()}</td>
            <td><IconButton
                    component="a"
                    href={createSearchUrl(`${item.street_address}, ${item.locality}, ${item.postcode}`)}
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <HikingIcon />
                </IconButton></td>
          </tr>
        ))}
      </tbody>
    </Table>
        </Box>


    </Box>
  );
}

export default DataTable;

