import React from 'react';
import { DataProvider } from './context/DataContext';
import './App.css';
import HomePage from './pages/HomePage';
import { CssVarsProvider } from '@mui/joy/styles';
import customTheme from './types/theme';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DisclaimerPage from './components/DisclaimerPage';

const App: React.FC = () => {
  return (
    <DataProvider>
      <CssVarsProvider theme={customTheme}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/disclaimer" element={<DisclaimerPage />} />

        </Routes>
      </Router>
      </CssVarsProvider>
    </DataProvider>
  );
};

export default App;
