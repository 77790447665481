import React, { useState, useEffect, useCallback, useContext } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import { useData } from '../context/DataContext';
import { SearchDataItem } from "../types/types";

const calculateBounds = (dataPoints: SearchDataItem[]) => {
    const validPoints = dataPoints.filter(point => point.lat !== null && point.lon !== null);
    const latitudes = validPoints.map(point => point.lat);
    const longitudes = validPoints.map(point => point.lon);
    if (latitudes.length === 0 || longitudes.length === 0) {
        console.log('--');
      return null;
    }
    const northEast = [Math.max(...latitudes), Math.max(...longitudes)];
    const southWest = [Math.min(...latitudes), Math.min(...longitudes)];
    return [southWest, northEast] as L.LatLngBoundsExpression;
  };

const MyMapInteraction: React.FC<{ bounds: L.LatLngBoundsExpression | null }> = ({ bounds }) => {
  const map = useMap();

  useEffect(() => {
    if (bounds) {
      map.fitBounds(bounds);
    }
  }, [bounds, map]);

  return null;
};

const CustomMap2: React.FC = () => {
  const { data } = useData();
  const [bounds, setBounds] = useState<L.LatLngBoundsExpression | null>(null);

  useEffect(() => {
    if (data && data.length > 0) {
      setBounds(calculateBounds(data));
    }
  }, [data]);

  const getColorByDate = (date: string) => {
    const now = new Date();
    const saleDate = new Date(date);
    const diff = now.getTime() - saleDate.getTime();
    const daysDiff = diff / (1000 * 3600 * 24);
    const opacity = Math.max(0.2, 1 - daysDiff / 3650); // 1 years fade out
    // return `rgba(255, 0, 0, ${opacity})`;
    return `rgba(255, 0, 0)`;
  };

  const icon = (color: string) => new L.Icon({
    // iconUrl: require("./red_circle.png"), // Adjust the icon path as necessary
    iconUrl: require("./LSlogo.png"), // Adjust the icon path as necessary
    iconSize: [30, 42],
    iconAnchor: [5, 5],
    popupAnchor: [-3, -10],
    className: color,
  });

  const markers = data.map((dataPoint, index) => (
    <Marker
      key={index}
      position={[dataPoint.lat, dataPoint.lon]}
      icon={icon(getColorByDate(dataPoint.contract_date))}
    >
      <Popup>
        Address: {dataPoint.street_address}, {dataPoint.locality}, {dataPoint.postcode}
        <br />
        Price: ${dataPoint.sale_price.toLocaleString()}
        <br />
        Date: {new Date(dataPoint.contract_date).toDateString()}
      </Popup>
    </Marker>
  ));

  return (
    <div>
      {bounds && (
        <MapContainer
          style={{ width: "100%", height: "65vh" }}
          bounds={bounds}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
          {markers}
          <MyMapInteraction bounds={bounds} />
        </MapContainer>
      )}
    </div>
  );
};

export default CustomMap2;





// import React, { useState, useEffect, useCallback, useContext } from "react";
// import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
// import L from "leaflet";
// import { useData } from '../context/DataContext';
// import { SearchDataItem } from "../types/types";


// const calculateBounds = (dataPoints: SearchDataItem[]) => {
//     const validPoints = dataPoints.filter(point => point.lat !== null && point.lon !== null);
//     const latitudes = validPoints.map(point => point.lat);
//     const longitudes = validPoints.map(point => point.lon);
//     if (latitudes.length === 0 || longitudes.length === 0) {
//         console.log('lengths are zero..???');
//       return null;
//     }
//     const northEast = [Math.max(...latitudes), Math.max(...longitudes)];
//     const southWest = [Math.min(...latitudes), Math.min(...longitudes)];
//     return [southWest, northEast] as L.LatLngBoundsExpression;
//   };
  

// const MyMapInteraction: React.FC = () => {
//   const map = useMap();

//   const onMove = useCallback(() => {
//   }, [map]);

//   useEffect(() => {
//     map.on("move", onMove);
//     return () => {
//       map.off("move", onMove);
//     };
//   }, [map, onMove]);

//   return null;
// };



// const CustomMap2: React.FC = () => {
//   const { data } = useData();
//   const [bounds, setBounds] = useState<L.LatLngBoundsExpression | null>(null);

//   useEffect(() => {
//     if (data && data.length > 0) {
//       setBounds(calculateBounds(data));
//     }
//   }, [data]);

//   const getColorByDate = (date: string) => {
//     const now = new Date();
//     const saleDate = new Date(date);
//     const diff = now.getTime() - saleDate.getTime();
//     const daysDiff = diff / (1000 * 3600 * 24);
//     const opacity = Math.max(0.2, 1 - daysDiff / 3650); // 1 years fade out
//     console.log('opacity: ' + opacity);
//     // return `rgba(255, 0, 0, ${opacity})`;
//     return `rgba(255, 0, 0)`;
//   };

//   const icon = (color: string) => new L.Icon({
//     iconUrl: require("./red_circle.png"), // Adjust the icon path as necessary
//     iconSize: [10, 10],
//     iconAnchor: [5, 5],
//     popupAnchor: [-3, -10],
//     className: color,
//   });

//   const markers = data.map((dataPoint, index) => (
//     <Marker
//       key={index}
//       position={[dataPoint.lat, dataPoint.lon]}
//       icon={icon(getColorByDate(dataPoint.contract_date))}
//     >
//       <Popup>
//         Address: {dataPoint.street_address}, {dataPoint.locality}, {dataPoint.postcode}
//         <br />
//         Price: ${dataPoint.sale_price.toLocaleString()}
//         <br />
//         Date: {new Date(dataPoint.contract_date).toDateString()}
//       </Popup>
//     </Marker>
//   ));

//   return (
//     <div>
//       {bounds && (
//         <MapContainer
//           style={{ width: "100%", height: "65vh" }}
//           bounds={bounds}
//           scrollWheelZoom={true}
//         >
//           <TileLayer
//             attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
//             url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
//           />
//           {markers}
//           <MyMapInteraction />
//         </MapContainer>
//       )}
      
//     </div>
//   );
// };

// export default CustomMap2;








