import React, { useState, useEffect } from 'react';
import { Card, Grid, Typography } from '@mui/joy';

interface WordAnimatorProps {
  words: string[];
  interval?: number; // Interval in milliseconds, default to 1000ms
}

const WordAnimator: React.FC<WordAnimatorProps> = ({ words, interval = 1000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, interval);

    return () => clearInterval(timer);
  }, [words, interval]);

  return (
    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}  container direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid >

            <Card variant="outlined"  sx={{ display: 'inline-block' }}>
              <Typography level="h4" component="div" sx={{ color: "black"}}>
                {words[currentIndex]}
              </Typography>
            </Card>
          </Grid>

        </Grid>
        </Grid>
  );
};

export default WordAnimator;
    