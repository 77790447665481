import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/joy";
import { useData } from "../context/DataContext";
import CustomMap2 from "../components/CustomMap2";
import LocalityStats from "../components/LocalityStats";
import config from '../config';

const MetricPanel: React.FC = () => {
  const { data } = useData();
  const [uniqueLocalitiesString, setUniqueLocalitiesString] = useState("");

  useEffect(() => {
    const localities = data.map((item) => item.locality);
    const uniqueLocalities = Array.from(new Set(localities));
    const uniqueLocalitiesString = uniqueLocalities.join(", ");
    setUniqueLocalitiesString(uniqueLocalitiesString);
  }, [data]);

  useEffect(() => {
    if (uniqueLocalitiesString) {
      fetchStatsData(uniqueLocalitiesString);
    }
  }, [uniqueLocalitiesString]);

  const fetchStatsData = async (localities: string) => {
    // console.log('fetching data:: ' + uniqueLocalitiesString);
    try {
      const queryParams = new URLSearchParams({ locations: localities });
      const encodedQuery = queryParams.toString();
      const response = await fetch(
        `${config.apiBaseUrl}/stats?${encodedQuery}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error fetching stats data:", error);
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid xs />
      <Grid xs={12} md={8}>
        <Typography level="h2" sx={{ marginTop: 2, marginBottom: 2 }}>
          Stats for period
        </Typography>
        <LocalityStats data={data} />

        <Typography level="h2" sx={{ marginTop: 2, marginBottom: 2 }}>
          Map of search results
        </Typography>

        <CustomMap2 />
      </Grid>
      <Grid xs />
    </Grid>
  );
};

export default MetricPanel;
