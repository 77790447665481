import React, { useState, useEffect } from 'react';
import { ResponsiveScatterPlot, ScatterPlotDatum, ScatterPlotRawSerie } from '@nivo/scatterplot';
import { Box, Button, Grid } from '@mui/joy';
import DataTable from './DataTable';
import { Typography } from '@mui/material';

import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails, {
  accordionDetailsClasses,
} from '@mui/joy/AccordionDetails';
import AccordionSummary, {
  accordionSummaryClasses,
} from '@mui/joy/AccordionSummary';
import CustomDivider from './CustomDivider';
import { SearchDataItem } from '../types/types';

// export type SearchDataItem = {
//     contract_date: string;
//     uets: number;
//     locality: string;
//     postcode: string;
//     sale_price: number;
//     street_address: string;
//     outlier: number;
// };

type CustomScatterPlotDatum = ScatterPlotDatum & {
    contract_date: string;
    street_address: string;
    locality: string;
    postcode: string;
    sale_price: number;
};

export type points = {
    x: Date;
    y: number;
}

interface ResponsiveChartProps {
    data: SearchDataItem[];
}

// Function to format numbers with commas
const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// Transform SearchDataItem[] to ScatterPlotRawSerie<CustomScatterPlotDatum>[], grouped by locality
const transformData = (data: SearchDataItem[]): ScatterPlotRawSerie<CustomScatterPlotDatum>[] => {
    const groupedData: { [key: string]: CustomScatterPlotDatum[] } = data.reduce((acc, item) => {
        if (!acc[item.locality]) {
            acc[item.locality] = [];
        }
        acc[item.locality].push({
            x: new Date(item.contract_date),
            y: item.sale_price,
            contract_date: item.contract_date,
            street_address: item.street_address,
            locality: item.locality,
            postcode: item.postcode,
            sale_price: item.sale_price
        });
        return acc;
    }, {} as { [key: string]: CustomScatterPlotDatum[] });

    return Object.keys(groupedData).map(locality => ({
        id: locality,
        data: groupedData[locality]
    }));
};

// Custom tooltip component
const CustomTooltip = ({ node }: { node?: { data: CustomScatterPlotDatum } }) => {
    if (!node) {
        return null; // or some default fallback UI
    }

    return (
        <div style={{ background: 'white', padding: '12px', border: '1px solid #ccc' }}>
            {/* <div><strong>Date:</strong> {node.data.contract_date}</div> */}
            <div><strong>Date:</strong> {new Date(node.data.contract_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })} </div>
            <div><strong>Address:</strong> {node.data.street_address}</div>
            <div><strong>Locality:</strong> {node.data.locality}</div>
            <div><strong>Postcode:</strong> {node.data.postcode}</div>
            <div><strong>Sale Price:</strong> ${numberWithCommas(node.data.sale_price)}</div>
        </div>
    );
};

const CustomScatterPlot: React.FC<ResponsiveChartProps> = ({ data }) => {
    const [filteredData, setFilteredData] = useState<SearchDataItem[]>(data);
    const [removedData, setRemovedData] = useState<SearchDataItem[]>([]);
    const [minDate, setMinDate] = useState<Date | null>(null);
    const [maxDate, setMaxDate] = useState<Date | null>(null);

    useEffect(() => {
        const dates = filteredData.map(item => new Date(item.contract_date));
        setMinDate(new Date(Math.min(...dates.map(date => date.getTime()))));
        setMaxDate(new Date(Math.max(...dates.map(date => date.getTime()))));
    }, [filteredData]);

    useEffect(() => {
        setFilteredData(data);
        setRemovedData([]);
    }, [data]);

    const removeLargestOutlier = () => {
        const maxPrice = Math.max(...filteredData.map(item => item.sale_price));
        const removedItem = filteredData.find(item => item.sale_price === maxPrice);
        if (removedItem) {
            setFilteredData(filteredData.filter(item => item.sale_price !== maxPrice));
            setRemovedData([...removedData, removedItem]);
        }
    };

    const removeSmallestOutlier = () => {
        const minPrice = Math.min(...filteredData.map(item => item.sale_price));
        const removedItem = filteredData.find(item => item.sale_price === minPrice);
        if (removedItem) {
            setFilteredData(filteredData.filter(item => item.sale_price !== minPrice));
            setRemovedData([...removedData, removedItem]);
        }
    };

    const transformedData = transformData(filteredData);

    return (
            <Grid container spacing={2}>
                <Grid  xs={12} sx={{ height: '50vh', width: "100%" }}>
                    {minDate && maxDate && (
                        <ResponsiveScatterPlot
                            data={transformedData}
                            margin={{ top: 30, right: 10, bottom: 80, left: 75 }}
                            xScale={{ type: 'time', format: '%Y-%m-%d', min: minDate, max: maxDate, precision: 'day' }}
                            xFormat="time:%Y-%m-%d"
                            yScale={{ type: 'linear', min: 0, max: 'auto' }}
                            yFormat=">-.2f"
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                format: '%b %y',
                                tickValues: 'every 31 days',
                                tickSize: 6,
                                tickPadding: 50,
                                tickRotation: 270,
                                legend: 'Date',
                                legendPosition: 'middle',
                                legendOffset: 65,
                                truncateTickAt: 0
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Sale Price ($)',
                                legendPosition: 'middle',
                                legendOffset: -60,
                                truncateTickAt: 0
                            }}
                            legends={[
                                {
                                    anchor: 'top',
                                    direction: 'column',
                                    justify: false,
                                    // translateX: 280,
                                    translateY: -25,
                                    itemWidth: 100,
                                    itemHeight: 12,
                                    itemsSpacing: 5,
                                    itemDirection: 'left-to-right',
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                            // colors={{ scheme: 'nivo' }} // Use Nivo's built-in color scheme
                            // colors={['#012a4a', '#a2acbd', '#6e7788', '#461928', '#794655']}

                            colors={['#ffadda','#35f3ff','#00bbfa','#0086c1','#1ba190', '#950066']}
                            tooltip={CustomTooltip}
                        />
                    )}
                </Grid>

                <Grid  xs={12} container justifyContent="center" alignItems="center" sx={{ margin: 3 }}>
                    <Button variant='outlined' onClick={removeLargestOutlier} sx={{ margin: 1 }}>Remove Largest</Button>
                    <Button variant='outlined' onClick={removeSmallestOutlier} sx={{ margin: 1 }}>Remove Smallest</Button>
                </Grid>

                <Grid  xs={12}>
                    <AccordionGroup
                        variant="outlined"
                        transition="0.2s"
                        sx={{
                            borderRadius: 'lg',
                            [`& .${accordionSummaryClasses.button}:hover`]: {
                                bgcolor: 'transparent',
                            },
                            [`& .${accordionDetailsClasses.content}`]: {
                                boxShadow: (theme) => `inset 0 1px ${theme.vars.palette.divider}`,
                                [`&.${accordionDetailsClasses.expanded}`]: {
                                    paddingBlock: '0.75rem',
                                },
                            },
                        }}
                    >
                        <Accordion>
                            <AccordionSummary>Outliers removed from chart</AccordionSummary>
                            <AccordionDetails variant="soft">
                                <Box sx={{ marginTop: 2, marginBottom: 10 }}>
                                    <Typography sx={{ marginBottom: 2 }}>Outliers removed from chart above:</Typography>
                                    <DataTable data={removedData} />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </AccordionGroup>
                </Grid>

                <Grid  xs={12}>
                    {/* <Box sx={{ height: 300, marginBottom: 6 }} /> */}
                    {/* <CustomDivider dividerTitle="end" /> */}
                </Grid>
            </Grid>
    );
};

export default CustomScatterPlot;






// // yFormat=">-.2f"
// import React, { useState, useEffect } from 'react';
// import { ResponsiveScatterPlot, ScatterPlotDatum, ScatterPlotRawSerie } from '@nivo/scatterplot';
// import { Box, Button, Grid } from '@mui/joy';
// import DataTable from './DataTable';
// import { Typography } from '@mui/material';



// import AccordionGroup from '@mui/joy/AccordionGroup';
// import Accordion from '@mui/joy/Accordion';
// import AccordionDetails, {
//   accordionDetailsClasses,
// } from '@mui/joy/AccordionDetails';
// import AccordionSummary, {
//   accordionSummaryClasses,
// } from '@mui/joy/AccordionSummary';
// import CustomDivider from './CustomDivider';





// export type SearchDataItem = {
//     contract_date: string;
//     uets: number;
//     locality: string;
//     postcode: string;
//     sale_price: number;
//     street_address: string;
//     outlier: number;
// };

// type CustomScatterPlotDatum = ScatterPlotDatum & {
//     contract_date: string;
//     street_address: string;
//     locality: string;
//     postcode: string;
//     sale_price: number;
// };

// export type points = {
//     x: Date;
//     y: number;
// }

// interface ResponsiveChartProps {
//     data: SearchDataItem[];
// }

// // Function to format numbers with commas
// const numberWithCommas = (x: number) => {
//     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }

// // Transform SearchDataItem[] to ScatterPlotRawSerie<CustomScatterPlotDatum>[], grouped by locality
// const transformData = (data: SearchDataItem[]): ScatterPlotRawSerie<CustomScatterPlotDatum>[] => {
//     const groupedData: { [key: string]: CustomScatterPlotDatum[] } = data.reduce((acc, item) => {
//         if (!acc[item.locality]) {
//             acc[item.locality] = [];
//         }
//         acc[item.locality].push({
//             x: new Date(item.contract_date),
//             y: item.sale_price,
//             contract_date: item.contract_date,
//             street_address: item.street_address,
//             locality: item.locality,
//             postcode: item.postcode,
//             sale_price: item.sale_price
//         });
//         return acc;
//     }, {} as { [key: string]: CustomScatterPlotDatum[] });

//     return Object.keys(groupedData).map(locality => ({
//         id: locality,
//         data: groupedData[locality]
//     }));
// };

// // Custom tooltip component
// const CustomTooltip = ({ node }: { node?: { data: CustomScatterPlotDatum } }) => {
//     if (!node) {
//         return null; // or some default fallback UI
//     }

//     return (
//         <div style={{ background: 'white', padding: '12px', border: '1px solid #ccc' }}>
//             <div><strong>Date:</strong> {node.data.contract_date}</div>
//             <div><strong>Address:</strong> {node.data.street_address}</div>
//             <div><strong>Locality:</strong> {node.data.locality}</div>
//             <div><strong>Postcode:</strong> {node.data.postcode}</div>
//             <div><strong>Sale Price:</strong> ${numberWithCommas(node.data.sale_price)}</div>
//         </div>
//     );
// };

// const CustomScatterPlot: React.FC<ResponsiveChartProps> = ({ data }) => {
//     const [filteredData, setFilteredData] = useState<SearchDataItem[]>(data);
//     const [removedData, setRemovedData] = useState<SearchDataItem[]>([]);
//     const [minDate, setMinDate] = useState<Date | null>(null);
//     const [maxDate, setMaxDate] = useState<Date | null>(null);

//     useEffect(() => {
//         const dates = filteredData.map(item => new Date(item.contract_date));
//         setMinDate(new Date(Math.min(...dates.map(date => date.getTime()))));
//         setMaxDate(new Date(Math.max(...dates.map(date => date.getTime()))));
//     }, [filteredData]);

//     useEffect(() => {
//         setFilteredData(data);
//         setRemovedData([]);
//     }, [data]);

//     const removeLargestOutlier = () => {
//         const maxPrice = Math.max(...filteredData.map(item => item.sale_price));
//         const removedItem = filteredData.find(item => item.sale_price === maxPrice);
//         if (removedItem) {
//             setFilteredData(filteredData.filter(item => item.sale_price !== maxPrice));
//             setRemovedData([...removedData, removedItem]);
//         }
//     };

//     const removeSmallestOutlier = () => {
//         const minPrice = Math.min(...filteredData.map(item => item.sale_price));
//         const removedItem = filteredData.find(item => item.sale_price === minPrice);
//         if (removedItem) {
//             setFilteredData(filteredData.filter(item => item.sale_price !== minPrice));
//             setRemovedData([...removedData, removedItem]);
//         }
//     };

//     const transformedData = transformData(filteredData);

//     return (
//         <Grid container sx={{ height: '90%'}}>

//         <Box sx={{ height: '100%', width: '100%' }}>
//             {minDate && maxDate && (
//                 <ResponsiveScatterPlot
//                     data={transformedData}
//                     margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
//                     xScale={{ type: 'time', format: '%Y-%m-%d', min: minDate, max: maxDate, precision: 'day' }}
//                     xFormat="time:%Y-%m-%d"
//                     yScale={{ type: 'linear', min: 0, max: 'auto' }}
//                     yFormat=">-.2f"
//                     axisTop={null}
//                     axisRight={null}
//                     axisBottom={{
//                         format: '%b %y',
//                         tickValues: 'every 31 days',
//                         tickSize: 6,
//                         tickPadding: 50,
//                         tickRotation: 270,
//                         legend: 'Date',
//                         legendPosition: 'middle',
//                         legendOffset: 65,
//                         truncateTickAt: 0
//                     }}
//                     axisLeft={{
//                         tickSize: 5,
//                         tickPadding: 5,
//                         tickRotation: 0,
//                         legend: 'Sale Price ($)',
//                         legendPosition: 'middle',
//                         legendOffset: -60,
//                         truncateTickAt: 0
//                     }}
//                     legends={[
//                         {
//                             anchor: 'bottom-right',
//                             direction: 'column',
//                             justify: false,
//                             translateX: 130,
//                             translateY: 0,
//                             itemWidth: 100,
//                             itemHeight: 12,
//                             itemsSpacing: 5,
//                             itemDirection: 'left-to-right',
//                             symbolSize: 12,
//                             symbolShape: 'circle',
//                             effects: [
//                                 {
//                                     on: 'hover',
//                                     style: {
//                                         itemOpacity: 1
//                                     }
//                                 }
//                             ]
//                         }
//                     ]}
//                     colors={{ scheme: 'nivo' }} // Use Nivo's built-in color scheme
//                     tooltip={CustomTooltip}
//                 />
//             )}

//             <Grid
//             container
//             direction="column"
//             justifyContent="center"
//             alignItems="center"
//             sx={{ marginBottom: 6, marginTop: 3}}
//             >
//                 <Grid xs={10}>

//                 <Button variant='outlined' onClick={removeLargestOutlier} sx={{ marginRight: 1 }}>Remove Largest Outlier</Button>
//                 <Button variant='outlined' onClick={removeSmallestOutlier}>Remove Smallest Outlier</Button>

            
//             <Box sx={{ marginBottom: 4 }} >

//             <AccordionGroup
//                 variant="outlined"
//                 transition="0.2s"
//                 sx={{
//                     // maxWidth: 400,
//                     borderRadius: 'lg',
//                     [`& .${accordionSummaryClasses.button}:hover`]: {
//                         bgcolor: 'transparent',
//                     },
//                     [`& .${accordionDetailsClasses.content}`]: {
//                         boxShadow: (theme) => `inset 0 1px ${theme.vars.palette.divider}`,
//                         [`&.${accordionDetailsClasses.expanded}`]: {
//                             paddingBlock: '0.75rem',
//                         },
//                     },
//                 }}
                
//                 >
//                 <Accordion >
//                     <AccordionSummary>Outliers removed from chart</AccordionSummary>
//                     <AccordionDetails variant="soft">
//                     <Box sx={{ marginTop: 2, marginBottom: 10 }}>
//                         <Typography sx={{ marginBottom: 2 }}>Outliers removed from chart above:</Typography>
//                         <DataTable data={removedData} />
//                         </Box>
//                     </AccordionDetails>
//                 </Accordion>
//                 </AccordionGroup>
                    
//                     </Box>
                    
                    
//                     </Grid>

//                 </Grid>

//         </Box>
//         <CustomDivider dividerTitle="end" />

//         </Grid>

//     );
// };

// export default CustomScatterPlot;

