import { Divider, Chip } from "@mui/joy";
import React from "react"

interface DividerTitle {
    dividerTitle: string;  // Defining the expected prop type
  }

const CustomDivider: React.FC<DividerTitle> = ({ dividerTitle}) => {
    return (
        <Divider sx={{ marginBottom: 4, marginTop: 6}}>
        <Chip variant="soft" color="neutral" size="sm">
          {dividerTitle}
        </Chip>
      </Divider>
    )
}

export default CustomDivider;
