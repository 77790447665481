import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/joy";
import { SearchDataItem } from "../types/types"; // Adjust the import path as necessary

type Stats = {
  locality: string;
  averagePrice: number;
  maxPrice: number;
  medianPrice: number;
  minPrice: number;
  numberOfSales: number;
};

const calculateStatistics = (data: SearchDataItem[]): Stats[] => {
  const localityStats: { [key: string]: SearchDataItem[] } = {};

  data.forEach(item => {
    if (!localityStats[item.locality]) {
      localityStats[item.locality] = [];
    }
    localityStats[item.locality].push(item);
  });

  const stats: Stats[] = Object.keys(localityStats).map(locality => {
    const sales = localityStats[locality];
    const prices = sales.map(sale => sale.sale_price).sort((a, b) => a - b);

    const totalSales = sales.length;
    const averagePrice = prices.reduce((a, b) => a + b, 0) / totalSales;
    const maxPrice = Math.max(...prices);
    const minPrice = Math.min(...prices);
    const medianPrice = prices[Math.floor(totalSales / 2)];

    return {
      locality,
      averagePrice: Math.floor(averagePrice),
      maxPrice,
      medianPrice,
      minPrice,
      numberOfSales: totalSales,
    };
  });

  return stats;
};

type LocalityStatsProps = {
  data: SearchDataItem[];
};

const LocalityStats: React.FC<LocalityStatsProps> = ({ data }) => {
  const stats = calculateStatistics(data);

  return (
    <div>

<Grid container spacing={1}>

      {stats.map(stat => (
        <Card  key={stat.locality} style={{ margin: "1em", maxWidth: '100%',
        width: 250 }}>
          <CardContent>
            <Typography level="h4">{stat.locality}</Typography>
            <Typography level="body-md">Average Price: ${stat.averagePrice.toLocaleString()}</Typography>
            <Typography level="body-md">Max Price: ${stat.maxPrice.toLocaleString()}</Typography>
            <Typography level="body-md">Median Price: ${stat.medianPrice.toLocaleString()}</Typography>
            <Typography level="body-md">Min Price: ${stat.minPrice.toLocaleString()}</Typography>
            <Typography level="body-md">Number of Sales: {stat.numberOfSales}</Typography>
          </CardContent>
        </Card>
      ))}
  </Grid>
    </div>
  );
};

export default LocalityStats;
