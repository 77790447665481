import React from "react";
import { Grid } from "@mui/material";
import Box from "@mui/joy/Box";
import DataTable from "./DataTable";
import { useData } from "../context/DataContext";
import { Typography } from "@mui/joy";


const TablePanel: React.FC = () => {

  const { data } = useData();



  return (
    <>
      <Grid container spacing={2} sx={{ flexGrow: 1, marginBottom: '120px' }}>
        <Grid xs md >
          {/* <Item>this is edge space this is edge space this is edge space this is edge space this is edge space this is edge space this is edge space </Item> */}
        </Grid>
        <Grid xs={12} md={8}>
          <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            <Grid xs={12}>
                {/* put column of charts here. */}
                <Box  >
                <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2, marginBottom: 3 }}
    >
                <Typography level="h2">Search Results</Typography>

    </Box>
                    <DataTable data={data} />

                </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs md>
          {/* <Item>this is edge space this is edge space this is edge space this is edge space this is edge space this is edge space this is edge space </Item> */}
        </Grid>
      </Grid>
    </>
  );
};

export default TablePanel;
